import React, { ReactNode } from "react";
import styles from "./pageHeader.module.css";

export const PageHeader = (props: {title: string, image: string, children?: ReactNode}) => {
    return  <header className={styles.headerWrapper}>
    <div className={styles.header}>
      <h1>{props.title}</h1>
    </div>
    <img src={props.image} />
    {props.children}
  </header>
}