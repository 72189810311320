import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import {PageHeader} from "../components/pageHeader/pageHeader";
import Layout from "../components/layout"
import SEO from "../components/seo"
import headerImage from "../../content/assets/code-resized.jpg"
import styles from "./blog.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRssSquare } from '@fortawesome/free-solid-svg-icons'
// import { resolveTags } from "../tags/resolveTags";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Blog" />
      <PageHeader title="Blog" image={headerImage}>
        <div className={styles.rss}>
          <a href="/rss.xml" target="_blank" title="RSS feed">
            <FontAwesomeIcon icon={faRssSquare} />
          </a>
        </div>
      </PageHeader>
      <ol className={styles.postList} style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          // const tags = resolveTags(post.frontmatter.tags); 

          const featuredImage =
            post.frontmatter.featuredImage?.childImageSharp.fluid
          return (
            <li key={post.fields.slug}>
              <article
                className={styles.post}
                itemScope
                itemType="http://schema.org/Article"
              >
                <Link to={post.fields.slug} itemProp="url">
                <header>
                  <h2>
                      <span itemProp="headline">{title}</span>

                  </h2>
                  {/* {tags.map(tag => <small>{tag.displayName}&nbsp;</small>)} */}
                  {/* <small>{post.frontmatter.date}</small> */}
                </header>
                <section className={styles.description}>
                  <Img fluid={featuredImage} className={styles.image} />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
                </Link>
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
